<template>

    <v-dialog
        transition="dialog-top-transition"
        max-width="90%"
        persistent
        v-model="dialog_baixa"
    >
      <template v-slot:default="">
        <v-card>
          <v-toolbar color="orange darken-3"  flat
          ><h2 class="font-weight-bold white--text">
           Solicitação de baixa do Protocolo
          </h2>

            <v-spacer></v-spacer>

              <v-text-field
                  prepend-inner-icon="mdi-lock "
                  label="Digite sua Senha"
                 

                  type="password"
                  class="pt-6 password"
                  rounded
           
           
                 filled 
                 background-color="white"
                 
                 text-color="primary"
                 
                  v-model="senha"
              >
              </v-text-field>

          </v-toolbar>
<!--          <v-card-text class="mt-2">-->

<!--              <v-select-->
<!--                  :items="motivos"-->
<!--                  label="Motivo"-->
<!--                  outlined-->
<!--                  dense-->
<!--                  hide-details-->
<!--                  item-text="motivo"-->
<!--                  item-value="id"-->
<!--                  v-model="motivo"-->
<!--                  color="orange"-->
<!--              ></v-select>-->

<!--          </v-card-text>-->
<!--          <v-card-text>-->
<!--            <v-flex xs12 md12 class="pa-1">-->
<!--              <v-textarea-->
<!--                  outlined-->
<!--                  v-model="motivo_observacao"-->
<!--                  clearable-->
<!--                  color="orange"-->
<!--                  clear-icon="mdi-close-circle"-->
<!--                  rows="1"-->
<!--                  dense-->
<!--                  hide-details-->
<!--                  label="Observação"-->
<!--                  value=""-->
<!--              ></v-textarea>-->
<!--            </v-flex>-->
<!--          </v-card-text>-->
          <v-card-text class="mt-3">
            <v-data-table
               hide-default-footer
                class="elevation-1"
            >
              <template v-slot:header>
                <tr bgcolor="orange">
                  <th>#</th>
                  <th>Nome</th>
                  <th>CPF/CNPJ</th>
                  <th>Lote</th>
                  <th>Protocolo</th>
                  <th>Nº Titulo</th>
                  <th>Data emissão</th>
                  <th>Data vencimento</th>
                  <th>Valor Original</th>
                  <th>Valor Vencido</th>
                </tr>
              </template>
              <template v-slot:body dense>
                <tr v-for="item in visualizacao" v-bind:key="item.protocolo">
                  <td class="text-center">{{ item.index }}</td>
                  <td class="text-center">{{ item.cliente.name }}</td>
                  <td class="text-center" >{{ formatarCpfCnpj(item.cliente.cpf_cnpj) }}</td>
                  <td class="text-center" >{{ item.lote }}</td>
                  <td class="text-center" >{{ item.protocolo }}</td>
                  <td class="text-center" >{{ item.numero_titulo }}</td>
                  <td class="text-center" >{{ converterDataTimestamp(item.data_emissao) }}</td>
                  <td class="text-center" >{{ converterDataTimestamp(item.data_vencimento) }}</td>
                  <td class="text-center" >{{ converterDinherio(item.valor_original) }}</td>
                  <td class="text-center" >{{ converterDinherio(item.valor_vencido) }}</td>

                </tr>
              </template>
            </v-data-table>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn  text color="red" @click="$emit('close')">Fechar</v-btn>
            <v-btn elevation="0" :disabled="save" @click="solicitarBaixa" text color="green">Solicitar Baixa</v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>

</template>

<script>
import helper from "../mixins/helper";

export default {
  name: "BaixaComponent",
  mixins: [helper],
  props:{
    visualizacao:{
      default:[]
    },
    protocolo:{
      default: ''
    },
    dialog_baixa:{
      default:false
    },
    motivos:{
      default:[]
    }

  },

  data: () => ({
    senha:null,
    save:true,
    motivo_observacao:null,
    motivo:null,
    dadosEnvio:{
      negativacoes: {}
    },
    headers_log: [

      // { text: '#'},
      { text: 'Data/Hora', value: 'data', align: 'start' },
      { text: 'Usuario', value: 'name', align: 'center' },
      { text: 'IP', value: 'user_ip', align: 'center' },
      { text: 'Transação', value: 'transacao', align: 'center' },
      { text: 'Ação', value: 'acao', align: 'center' },
      { text: 'Motivo', value: 'motivo', align: 'center' },
      { text: 'Observação', value: 'observacao', width: "15%", align: 'center' },
      { text: 'Status', value: 'status_nome', width: "15%", align: 'center' },

    ],
  }),

  created() {


  },
  mounted() {

  },

  methods:{
      close(){
        this.dialog_baixa = false;
      },

    fecharAtualizar(){
        this.senha = null
        this.$emit('close')
        this.$emit('atualizar')
    },


    solicitarBaixa(){

      this.$http.post("protocolos/baixa", {dados:this.visualizacao, senha:this.senha}).then(
          (response) => {
            console.log(response)
           this.notificarPopupCallback(this, response.body.message, 'success',  this.fecharAtualizar)
          },
          (response) => {
            this.notificar(this, 'Desculpe', response.body.message)

            // error callback
          }
      );
    }
  },

  watch:{
    senha(newValue){
      if(newValue.length >= 8){
        this.save = false
      }else{
        this.save = true
      }
    }
  }
}
</script>

<style scoped>
.password{
  color: red;
}

</style>