<template>
  <v-layout style="margin-top: 10px" row class="card-stone-background">
    <v-row style="margin-top: 10px; margin-bottom: 5px">
      <v-col xs="12" md="3" lg="3">
        <v-datetime-field
          v-model="busca.dataInsercaoIni"
          only-date
          filled
          rounded
          dense
          background-color="white"
          color="blue darken-4"
          labelDate="Inserção Início"
        ></v-datetime-field>
      </v-col>
      <v-col xs="12" md="3" lg="3">
        <v-datetime-field
          v-model="busca.dataInsercaoFim"
          only-date
          filled
          rounded
          dense
          background-color="white"
          color="blue darken-4"
          labelDate="Inserção Fim"
        ></v-datetime-field>
      </v-col>
      <v-col xs="12" md="3" lg="3">
        <v-text-field
          v-model="busca.protocolo"
          prepend-inner-icon="mdi-clipboard-text-outline "
          label="Protocolo"
          filled
          rounded
          dense
          background-color="white"
          color="blue darken-4"
          hide-details
        ></v-text-field>
      </v-col>
      <v-col xs="12" md="3" lg="3">
        <v-select
          v-model="busca.status"
          :items="status"
          item-text="nome"
          item-value="cod"
          filled
          rounded
          dense
          background-color="white"
          color="blue darken-4"
          label="Status"
        >
          <template v-slot:no-data>
            <h5 class="pa-2">Sem dados no momento</h5>
          </template>
        </v-select>
      </v-col>
     <!--  <v-col xs="12" md="6" lg="6">
        <v-autocomplete
          v-model="busca.bEmpresa"
          :items="states"
          label="Credor"
          item-text="name"
          filled
          rounded
          dense
          background-color="white"
          color="blue darken-4"
          item-value="id"
          prepend-inner-icon="mdi-domain"
          :loading="isLoading"
          :search-input.sync="search"
          flat
          hide-details
          return-object
        >
          <template slot="selection" slot-scope="{ item }">
            {{ item.name }} - {{ formatarCpfCnpj(item.cpf_cnpj) }}
          </template>

          <template slot="item" slot-scope="{ item }">
            {{ item.name }} - {{ formatarCpfCnpj(item.cpf_cnpj) }}
          </template>
        </v-autocomplete>
      </v-col>
 -->
      <v-col xs="12" md="6" lg="6">
        <v-autocomplete
          v-model="busca.devedor"
          :items="devedores"
          label="Devedor"
          item-text="name"
          filled
          rounded
          dense
          background-color="white"
          color="blue darken-4"
          hide-no-data
          item-value="id"
          prepend-inner-icon="mdi-account"
          :loading="isLoading"
          :search-input.sync="searchDevedor"
          :filter="(v) => v"
          flat
          hide-details
          clearables
          return-object
        >
          <template slot="selection" slot-scope="{ item }">
            {{ item.name }} - {{ formatarCpfCnpj(item.cpf_cnpj) }}
          </template>

          <template slot="item" slot-scope="{ item }">
            {{ item.name }} - {{ formatarCpfCnpj(item.cpf_cnpj) }}
          </template>
        </v-autocomplete>
      </v-col>
    </v-row>

    <v-flex
      xs12
      md12
      style="margin-bottom: 15px; margin-top: 3px"
      class="px-1 d-flex justify-end"
    >
      <v-btn
        elevation="2"
        class="primary mr-1"
        color="white--text "
        @click="buscar"
        :loading="ldgBuscar"
      >
        <v-icon small>mdi-magnify</v-icon>
        buscar
      </v-btn>
      <v-btn
        elevation="2"
        class="mr-1"
        color="#004296"
        @click="gerar_Pdf"
        :loading="ldgPdf"
      >
        <v-icon small color="#FFD400">mdi-file-export</v-icon>
        <span style="color: #ffd400; font-weight: 600"> Imprimir pdf</span>
      </v-btn>
      <v-btn elevation="1" color="#FFD400" @click="limpar">
        <v-icon small color="#0052A0">mdi-close-circle</v-icon>
        <span style="color: #0052a0; font-weight: 600"> Limpar</span>
      </v-btn>
    </v-flex>
  </v-layout>
</template>

<script>
import helper from "../mixins/helper";

export default {
  components: {},
  name: "SearchProtocolsComponent",
  mixins: [helper],
  props: {
    url: {
      default: "protocolos",
    },
  },
  data: () => ({
    status: [],
    states: [],
    dataRegister: [],
    search: null,
    ldgBuscar: false,
    searchDevedor: null,
    isLoading: null,
    devedores: [],
    ldgPdf: false,
    busca: {
      devedor: null,
      protocolo: null,
      status: null,
      bEmpresa: null,
      dataInsercaoFim: null,
      dataInsercaoIni: null,
    },
  }),
  mounted() {
    this.getStatus();
    this.buscar();
  },
  methods: {
    limpar() {
      this.busca.protocolo = null;
      this.busca.bEmpresa = null;
      this.busca.devedor = null;
      this.busca.status = null;
      this.dataInsercaoFim = null;
      this.dataInsercaoFim = null;
      // this.$emit("emit-busca", null, null);
    },

    getStatus() {
      this.$http.get("status_protocolo").then(
        (response) => {
          this.status = response.body.data;
        },
        (response) => {
          console.log(response);
          // error callback
        }
      );
    },

    gerar_Pdf() {
      this.ldgPdf = true;

      if (this.dataRegister.length > 0) {
        var obss = "";
        if (this.dataInsercaoIni != null) {
          obss =
            +`Data de inserção: ${this.dataInsercaoIni} à ${this.dataInsercaoFim} `;
        }
        this.$emit("emit-pdf", this.dataRegister, obss);
      } else {
        this.notificacao("Nada para exibir", "", "info");
      }
      this.ldgPdf = false;
    },

    notificacao(title, msg, icon) {
      this.$swal({
        toast: true,
        position: "center-end",
        showConfirmButton: false,
        timer: 3000,
        icon: icon,
        title: title,
        text: msg,
      });
    },
    getdataInsercaoIni(data) {
      this.busca.dataInsercaoIni = data;
    },
    getdataInsercaoFim(data) {
      this.busca.dataInsercaoFim = data;
    },
    buscar() {
      this.ldgBuscar = true;
   
      this.$http.post(this.url, this.busca).then(
        (response) => {
          console.log(response);
          this.dataRegister = response.body.data;
          this.$emit("emit-busca", response.body.data, response.body);
          this.ldgBuscar = false;
        },
        (response) => {
          console.log(response);
          // error callback
          this.ldgBuscar = false;
        }
      );
    },
  },
  watch: {
    search(newValue) {
      this.isLoading = true;
      if (newValue != null) {
        if (newValue.length > 3) {
          this.$http.post("searchEmpresa", { bEmpresa: newValue }).then(
            (response) => {
              this.isLoading = false;
              this.states = response.body;
            },
            (response) => {
              console.log(response);
              // error callback
            }
          );
        }
      }
      this.isLoading = false;
    },
    searchDevedor(newValue) {
      if (newValue != null) {
        if (newValue.length > 3) {
          this.$http.post("searchDevedor", { busca: newValue }).then(
            (response) => {
              this.devedores = response.body;
            },
            (response) => {
              console.log(response);
              // error callback
            }
          );
        }
      }
    },
  },
};
</script>

<style scoped>
</style>