

import { mapActions } from "vuex";
import { isCNPJ, isCPF } from "vuetify-masks-br";
import axios from "axios";
import paginationComponent from "../../components/paginationComponent";
import DataComponent from "../../components/DataComponent";
import helper from "../../mixins/helper";

export default {
  components: { paginationComponent, DataComponent },

  data: () => ({
    dataHoje: null,
    mixins: [helper],
    ldg_enviar: false,
    qtTelefone: 1,
    qtEmail: 1,
    ca_endereco: '',
    dialogCoautor: false,
    coautores: [],
    coautorEditForm: {
      name: "",
      data_nascimento: null,
      cpf_cnpj: "",

      rg: "",
      endereco: "",
      bairro: "",
      numero: "",
      municipio: "",
      uf: "",
      cep: "",
      cepConvertido: "",
      telefone: "",
      telefone2: "",
      telefone3: "",
      email: "",
      email2: "",
      email3: "",
      observacao: "",
      user_juridica: false
    },
    alertar: false,
    dialog: false,
    lote: false,
    isArquivo: false,
    column: null,
    isDisabled: false,
    loading: false,
    pagination: [],
    ufs: [
      'RO', 'AC', 'AM', 'RR', 'PA', 'AP', 'TO', 'MA', 'PI', 'CE', 'RN', 'PB', 'PE', 'AL', 'SE', 'BA', 'MG', 'ES', 'RJ', 'SP', 'PR', 'SC', 'RS', 'MS', 'MT', 'GO', 'DF'],
    list: [],
    csv: [],
    linhas: [],
    isTable: false,
    isSend: false,
    row: "cpf",
    row2: "cpf",
    items: [],
    menu: false,
    isDownload: false,
    anexo_lote: null,
    arquivo_lote: null,
    form: {
      devedor: {
        name: "",
        data_nascimento: null,
        cpf_cnpj: "",

        rg: "",
        endereco: "",
        bairro: "",
        numero: "",
        municipio: "",
        uf: "",
        cep: "",
        cepConvertido: "",
        telefone: "",
        telefone2: "",
        telefone3: "",
        email: "",
        email2: "",
        email3: "",
        observacao: "",
        user_juridica: false,
      },
      divida: {
        numero_titulo: null,
        nosso_numero: null,
        especie: null,
        valor_original: null,
        valor_vencido: null,
        data_emissao: "",
        data_vencimento: "",
        observacao: "",
        file: null,
      },
    },
    emailRule: [
      (v) => !!v || "E-mail é obrigatória",
      (v) => /.+@.+\..+/.test(v) || "insira um email E-mail válido",
    ],
    cpfRule: [
      (v) => !!v || "CPF é obrigatória",
      (v) => isCPF(v) || "CPF não é válido.",
    ],
    cnpjRule: [
      (v) => !!v || "CNPJ é obrigatória",
      (v) => isCNPJ(v) || "CNPJ não é válido.",
    ],
  }),

  created() {
    /* window.addEventListener('beforeunload', function(event) {
        event.returnValue = 'Write something'
     })*/
  },


  /* beforeWindowUnload (to, from, next) {
     // If the form is dirty and the user did not confirm leave,
     // prevent losing unsaved changes by canceling navigation
  
     if(this.confirmLeave()){
     next(true)
     }else{
       return false;
     }
    
     if (this.confirmStayInDirtyForm()){
       next(false)
     } else {
       // Navigate to next view
       return false;
     }
   },
   beforeRouteLeave (to, from, next) {
     // If the form is dirty and the user did not confirm leave,
     // prevent losing unsaved changes by canceling navigation
 
     if(this.confirmLeave()){
     next(true)
     }else{
       return false;
     }
    
     if (this.confirmStayInDirtyForm()){
       next(false)
     } else {
       // Navigate to next view
       return false;
     }
   },*/

  mounted() {
    /*this.notificacao(
      "Atenção",
      "Preencha todos os campos obrigatórios!",
      "error"
    );*/

    this.getEspecies()

    this.dataHoje = new Date().toISOString()
    var date = new Date();
    date.setDate(date.getDate()-1);


    this.dataHoje = date.toISOString()

    this.dataHoje = this.dataHoje.split("T")
    this.dataHoje = this.dataHoje[0]

    if (this.$store.state.auth.user.empresas.length <= 0) {
      this.alertar = true
    }
  },
  methods: {
    ...mapActions("auth", ["enviarDividaFile", "ActionSendDividaLote"]),
    filtroTeclas(event) {

      this.form.devedor.cpf_cnpj = ('0123456789'.search(event.key) > 0);

    },

    confirmLeave() {

      //  return window.confirm('Você deseja mesmo sair e perder todos os dados?')
    },



    confirmStayInDirtyForm() {

      return this.form_dirty && !this.confirmLeave()
    },

    beforeWindowUnload(e) {

      if (this.confirmStayInDirtyForm()) {
        // Cancel the event
        e.preventDefault()
        // Chrome requires returnValue to be set
        e.returnValue = ''
      }
    },
    removerNumero() {
      if (this.qtTelefone == 3) {
        this.qtTelefone = 2;
        this.form.devedor.telefone3 = "";
        return null
      }
      if (this.qtTelefone == 2) {
        this.qtTelefone = 1;
        this.form.devedor.telefone2 = "";
        return null
      }

    },
    removerMail() {
      if (this.qtEmail == 3) {
        this.qtEmail = 2;
        this.form.devedor.email3 = "";
        return null
      }
      if (this.qtEmail == 2) {
        this.qtEmail = 1;
        this.form.devedor.email2 = "";
        return null
      }

    },
    getEspecies() {
      this.$http.get("getEspecies").then(
        (response) => {
          this.items = response.data
          console.log(this.itens)
        },
        (response) => {
          console.log(response);
        }
      );
    },
    CadastrarEmpresa() {
      this.$router.push({ name: "empresas" });
    },
    excluirCoautor(id) {

      this.coautores.splice(id, 1);

    },
    fecharCoautor() {
      this.dialogCoautor = false;
      this.coautorEditForm = [];

    },
    salvarCoautor() {
      /*
    alert( (this.coautorEditForm.name??'').length  )
    alert(  (this.coautorEditForm.cpf_cnpj??'').length)
    alert(  (this.coautorEditForm.endereco??'').length)
    alert(  (this.coautorEditForm.bairro??'').length)
    alert(  (this.coautorEditForm.numero??'').length)
    alert(  (this.coautorEditForm.municipio??'').length)
    alert(  (this.coautorEditForm.cep??'').length)
    alert(  (this.coautorEditForm.telefone??'').length)
    alert(  (this.coautorEditForm.email??'').length)*/


      if (
        (this.coautorEditForm.name ?? '').length > 3 &&
        (this.coautorEditForm.cpf_cnpj ?? '').length > 3 &&
        (this.coautorEditForm.endereco ?? '').length > 3 &&
        (this.coautorEditForm.bairro ?? '').length > 3 &&
        (this.coautorEditForm.numero ?? '').length > 0 &&
        (this.coautorEditForm.municipio ?? '').length > 3 &&
        (this.coautorEditForm.cep ?? '').length > 3 &&
        (this.coautorEditForm.telefone ?? '').length > 1 &&
        (this.coautorEditForm.email ?? '').length > 3
      ) {
        if (typeof this.coautorEditForm.id !== 'undefined') {
          this.coautores[this.coautorEditForm.id] = this.coautorEditForm;
          this.dialogCoautor = false;
        } else {
          console.log(this.coautorEditForm);
          this.coautores.push(this.coautorEditForm);
          this.dialogCoautor = false;

        }
        this.coautorEditForm = {};

      } else {
        return this.notificacao("Campos obrigatórios não preechidos", 'Gentileza verificar os dados informados para prossegui com o cadastro', "error");
      }



    },
    formCoautor(id) {
      this.inicializarCoAutor();
      if (id == -1) {
        console.log(id)
        this.dialogCoautor = true;
        // this.coautorEditForm = [];
      } else {
        this.coautorEditForm = this.coautores[id];
        this.coautorEditForm.id = id;
        this.dialogCoautor = true;
      }
    },
    home() {
      this.$router.push({ name: "home" });
    },
    loteOpen() {
      this.lote = true;
    },
    coautoresSerialize() {
      var coautoreArray = [];

      this.coautores.map(function (item) {
        coautoreArray.push({
          name: item.name,
          bairro: item.bairro,
          cep: item.cep,
          email: item.email,
          email2: item.email2,
          email3: item.email3,
          endereco: item.endereco,
          numero: item.numero,
          municipio: item.municipio,
          cpf_cnpj: item.cpf_cnpj,
          rg: item.rg,
          observacao: item.observacao,
          telefone: item.telefone,
          uf: item.uf,
        })

      })

      return coautoreArray;
    },
    async enviarCoAutor(dividaID) {
      //const tempCoautores = this.coautores;
      var teste_coautores = this.coautoresSerialize();
      //alert( this.coautores )


      try {
        const { data: result } = await this.$http.post(
          `enviarDividaFile/${dividaID}/coautores`,
          {
            coautores: teste_coautores
          }
        );
        console.log(result);

        //console.log(result);
      } catch (error) {
        //
      } finally {
        //
      }

    },
    async EnviarDivida() {

      const coAutores = this.coautoresSerialize();


      if (this.$refs.form.validate()) {
        let formData = new FormData();
        formData.append("devedor['name']", this.form.devedor.name);
        formData.append("devedor['cpf_cnpj']", this.form.devedor.cpf_cnpj);
        formData.append("devedor['data_nascimento']", this.form.devedor.data_nascimento);
        formData.append("devedor['rg']", this.form.devedor.rg);
        formData.append("devedor['endereco']", this.form.devedor.endereco);
        formData.append("devedor['bairro']", this.form.devedor.bairro);
        formData.append("devedor['numero']", this.form.devedor.numero);
        formData.append("devedor['municipio']", this.form.devedor.municipio);
        formData.append("devedor['uf']", this.form.devedor.uf);
        formData.append("coautores", coAutores);

        formData.append(
          "devedor['cep']",
          this.form.devedor.cep.replace(/([^\d])+/gim, "")
        );
        formData.append("devedor['telefone']", this.form.devedor.telefone.replace(/([^\d])+/gim, ""));
        formData.append("devedor['telefone2']", this.form.devedor.telefone2.replace(/([^\d])+/gim, ""));
        formData.append("devedor['telefone3']", this.form.devedor.telefone3.replace(/([^\d])+/gim, ""));
        formData.append("devedor['email']", this.form.devedor.email);
        formData.append("devedor['email2']", this.form.devedor.email2);
        formData.append("devedor['email3']", this.form.devedor.email3);
        formData.append("devedor['observacao']", this.form.devedor.observacao);
        formData.append(
          "devedor['user_juridica']",
          this.form.devedor.user_juridica
        );

        formData.append(
          "divida['numero_titulo']",
          this.form.divida.numero_titulo
        );
        formData.append(
          "divida['nosso_numero']",
          this.form.divida.nosso_numero
        );
        formData.append("divida['especie']", this.form.divida.especie);
        formData.append(
          "divida['valor_original']",
          this.form.divida.valor_original
        );
        formData.append(
          "divida['valor_vencido']",
          this.form.divida.valor_vencido
        );
        formData.append(
          "divida['data_emissao']",
          this.form.divida.data_emissao
        );
        formData.append(
          "divida['data_vencimento']",
          this.form.divida.data_vencimento
        );
        formData.append("divida['observacao']", this.form.divida.observacao);
        formData.append("divida['file']", this.form.divida.file);

        await this.sendArquivos(formData);
        //this.ldg_enviar = false
      } else {
        this.notificacao(
          "Atenção",
          "Preencha todos os campos obrigatórios!",
          "error"
        );
        //this.ldg_enviar = false
      }

    },

    async sendArquivos(formdata) {
      this.ldg_enviar = true;
      // console.log(formdata);

      //const data = await this.enviarDividaFile(formdata);

      /*if (data.data.erro) {
        alert("Erro")
        //this.notificacao("Erro", data.data.message, "error");
       
        
      } else {
        this.enviarCoAutor(data.data.divida_id)
        this.notificacao("Sucesso", data.data.message, "success");
        this.ldg_enviar = false;
        // this.getNegativar();
        //this.dialog = false;
        // this.$router.push({ name: "Consulta de Títulos" });
       // this.$router.push({ path: "/" });
      }
      */

      this.$http.post('enviarDividaFile', formdata).then(
        (response) => {

          this.enviarCoAutor(response.data.divida_id)
          this.$swal({
            icon: 'success',
            customClass: {
              confirmButton: "btn btn-success",
              cancelButton: "btn btn-danger",
            }, buttonsStyling: false,
            title: "Negativação enviada com sucesso"
          })
          this.ldg_enviar = false;
          // this.getNegativar();
          //this.dialog = false;
          this.$router.push({ name: "Consulta de Títulos" });
          this.$router.push({ path: "/" });

        },
        (response) => {

          this.$swal({
            icon: 'error',
            customClass: {
              confirmButton: "btn btn-success",
              cancelButton: "btn btn-danger",
            }, buttonsStyling: false,
            title: "Não foi possível completar registro",
            text: response.data.message,
            confirmButtonColor: '#8CD4F5',

          })
          //this.notificacao("Erro ao registrar dívida",response.data,"error")
          this.ldg_enviar = false;
          // this.getNegativar();
          //this.dialog = false;

        }
      )


    },
    inicializarCoAutor() {
      this.coautorEditForm =
      {
        name: "",
        data_nascimento: null,
        cpf_cnpj: "",

        rg: "",
        endereco: "",
        bairro: "",
        numero: "",
        municipio: "",
        uf: "",
        cep: "",
        cepConvertido: "",
        telefone: "",
        telefone2: "",
        telefone3: "",
        email: "",
        email2: "",
        email3: "",
        observacao: "",
        user_juridica: false
      }

    },
    notificacao(title, msg, icon) {
      this.$swal({
        toast: true,
        position: "center",
        showConfirmButton: false,
        timer: 3000,
        icon: icon,
        title: title,
        text: msg,
      });
    },
    save(date) {
      this.$refs.menu.save(date);
    },
    getNascimento(data) {
      this.form.devedor.data_nascimento = data
    },
    getEmissao(data) {
      this.form.divida.data_emissao = data
    },

    async setarEnderecoCep(res) {
      var vm = this.coautorEditForm;
      console.log(vm)
      vm.municipio = res.data.localidade;
      vm.uf = res.data.uf;
      vm.bairro = res.data.bairro;
      vm.endereco = res.data.logradouro;


    },
    getVencimento(data) {

      this.form.divida.data_vencimento = data


    }


  },

  watch: {
    "form.devedor.cep": function (newValue) {
      var vm = this.form.devedor;
      var values = this;
      var local = newValue.replace(/([^\d])+/gim, "");

      if (local.length == 8) {
        axios
          .get("https://viacep.com.br/ws/" + local + "/json/")
          .then(function (res) {
            if (!res.data.erro) {
              vm.municipio = res.data.localidade;
              vm.uf = res.data.uf;
              vm.bairro = res.data.bairro;
              vm.endereco = res.data.logradouro;
            } else {
              values.$swal({
                toast: true,
                position: "center-end",
                showConfirmButton: false,
                background: "#ff0000",
                timer: 3000,
                icon: "error",
                title: '<span style="color:#FFF">Desculpe</span>',
                html: '<span style="color:#FFF">CEP não encontrado</span>',
              });
            }
          })
          .catch(function (err) {
            err;
          });
      }
    },
    "coautorEditForm.cep": function (newValue) {

      var vm = this.coautorEditForm;
      var values = this;
      var local = newValue.replace(/([^\d])+/gim, "");

      if (local.length == 8) {

        axios
          .get("https://viacep.com.br/ws/" + local + "/json/")
          .then(async function (res) {
            console.log(res)
            if (!res.data.erro) {
              //await this.setarEnderecoCep(res);
              console.log(vm)
              vm.municipio = res.data.localidade;
              vm.uf = res.data.uf;
              vm.bairro = res.data.bairro;
              vm.endereco = res.data.logradouro;

              alert(this.coautorEditForm)

            } else {
              values.$swal({
                toast: true,
                position: "center-end",
                showConfirmButton: false,
                background: "#ff0000",
                timer: 3000,
                icon: "error",
                title: '<span style="color:#FFF">Desculpe</span>',
                html: '<span style="color:#FFF">CEP não encontrado</span>',
              });
            }
          })
          .catch(function (err) {
            err;
          });
      }


    },
    row: function (newValue) {
      if (newValue == "cpf") {
        this.form.devedor.user_juridica = false;
      } else {
        this.form.devedor.user_juridica = true;
      }
      this.form.devedor.cpf_cnpj = "";
      this.form.devedor.rg = "";
      this.form.devedor.data_nascimento = null;
      this.isDisabled = false;
      this.form.devedor.name = "";
      this.form.devedor.rg = "";
      this.form.devedor.endereco = "";
      this.form.devedor.bairro = "";
      this.form.devedor.numero = "";
      this.form.devedor.municipio = "";
      this.form.devedor.uf = "";
      this.form.devedor.cep = "";
      this.form.devedor.telefone = "";
      this.form.devedor.telefone2 = "";
      this.form.devedor.telefone3 = "";
      this.form.devedor.email = "";
      this.form.devedor.email2 = "";
      this.form.devedor.email3 = "";
      this.form.devedor.observacao = "";
    },
    row2: function (newValue) {
      if (newValue == "cpf") {
        this.coautorEditForm.user_juridica = false;
      } else {
        this.coautorEditForm.user_juridica = true;
      }
      this.coautorEditForm.cpf_cnpj = "";
      this.coautorEditForm.rg = "";
      this.coautorEditForm.data_nascimento = null;
      this.isDisabled = false;
      this.coautorEditForm.name = "";
      this.coautorEditForm.rg = "";
      this.coautorEditForm.endereco = "";
      this.coautorEditForm.bairro = "";
      this.coautorEditForm.numero = "";
      this.coautorEditForm.municipio = "";
      this.coautorEditForm.uf = "";
      this.coautorEditForm.cep = "";
      this.coautorEditForm.telefone = "";
      this.coautorEditForm.telefone2 = "";
      this.coautorEditForm.telefone3 = "";
      this.coautorEditForm.email = "";
      this.coautorEditForm.email2 = "";
      this.coautorEditForm.email3 = "";
      this.coautorEditForm.observacao = "";
    },
    "form.devedor.cpf_cnpj": function (newValue) {

      // newValue = newValue.replace(/([^\d])+/gim, "");


      var local = newValue.replace(/([^\d])+/gim, "");
      var vm = this;
      if (this.row == "cpf") {
        if (local.length == 11) {
          this.loading = true;
          this.$http.get("getUsuario/" + local).then(
            (response) => {
              // get body data
              if (response.data.erro != true) {

                vm.form.devedor.name = response.data.name;
                vm.form.devedor.cpf_cnpj; 1
                vm.isDisabled = true;
              }

              this.loading = false;
              // vm.form.devedor.data_nascimento = response.data.data_nascimento
            },
            (response) => {
              console.log(response);
              // error callback
            }
          );
        }
      } else {
        if (local.length == 14) {
          this.loading = true;
          this.$http.get(`buscarEmpresaReceita/${local}`).then(
            (response) => {
              console.log(response)
              vm.form.devedor.cpf_cnpj;
              vm.form.devedor.name = response.data.nome;
              vm.form.devedor.endereco = response.data.endereco;
              vm.form.devedor.bairro = response.data.bairro;
              vm.form.devedor.numero = response.data.numero;
              vm.form.devedor.municipio = response.data.municipio;
              vm.form.devedor.uf = response.data.uf;
              vm.form.devedor.cep = response.data.cep;
              vm.form.devedor.telefone = response.data.telefone;
              // vm.form.devedor.telefone2 = response.data.telefone2;
              //vm.form.devedor.telefone3 = response.data.telefone3;
              vm.form.devedor.email = response.data.email;
              //vm.form.devedor.email2 = response.data.email2;
              //vm.form.devedor.email3 = response.data.email3;
              vm.form.devedor.observacao = response.data.observacao;
              vm.isDisabled = true;
              this.loading = true;
            },
            (response) => {
              console.log(response);
              // error callback
            }
          );
        }
      }
    },
    menu(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = "YEAR"));
    }

  }
}